<template>
  <div class="help-other">
    <div class="item-info" style="height:1100px">
      <div
        class="item"
        v-for="(item, index) in helpList.list"
        :key="index"
        @click="detail(item.id)"
         style="display:flex;flex-direction:column"
      >
        <div class="item-title" style="font-size:1.25rem;width:100%">{{ item.title }}</div>
        <div class="content" style="font-size:1rem!important;display:block;width:100%" v-html="item.content"></div>
        <div style="margin-top:5px;background-color:#ff547b;width:140px;text-align:center;height:30px;line-height:30px;border-radius:20px;color:#fff;cursor: pointer;">付费阅读：3元/次</div>
      </div>
    </div>
          <div  class="float-window"  v-if="isVisible" style="width:300px;height:400px;background-color:#ccc;margin:0 auto;padding:20px">
            <img src="../../../assets/images/7551721207967_.pic.jpg" style="width:300px">
             <div class="close-button" @click="closeFloatingWindow" style="color:#000;background-color:#fff;borer-radius:50px">×</div>
          </div>

  </div>

</template>

<script>
import { helpList, helpOther } from "@/api/cms/help";
export default {
  name: "list_other",
  components: {},
  data: () => {
    return {
      helpList: [],
      id: null,
      isVisible: false
    };
  },
  created() {
    this.id = this.$route.path.replace("/cms/help/listothers-5", "");
    this.getInfo();
  },
  watch: {
    $route(curr) {
      this.id = curr.params.pathMatch;
      this.getInfo();
    }
  },
  methods: {
    detail(id) {
      //this.$router.push({ path: "/cms/help-" + id });
      this.isVisible = true;
    },
    closeFloatingWindow() {
       this.isVisible = false;
    },
    getInfo() {
      helpOther({
        class_id: 5
      })
        .then(res => {
          if (res.code == 0 && res.data) {
            this.helpList = res.data;
          } else {
            this.$router.push({ path: "/cms/help" });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width:30px;
  height:30px;
  line-height:30px;
  font-size: 20px;
  cursor: pointer;
  text-align:center;
  border-radius:50px;
}
  .float-window {
    position: absolute;
    top: 30%;
    left: 34%;
    z-index: 100;
    border-radius:20px
  }
  .window-content {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
.help-other {
  .item-info {
    padding: 10px;
    background-color: #ffffff;
    height: 300px;
    border: 1px solid #e9e9e9;
    .item {
      border-bottom: 1px #f1f1f1 solid;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        padding-top: 0px;
      }
      .item-title {
        font-size: $ns-font-size-base;
        color: #333333;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          color: $base-color;
        }
      }
    }
  }
}
</style>
